import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { MasterContext } from 'contexts/master';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const EditCategory = () => {
  const { id } = useParams();

  const [form, setForm] = useState({ menu_category_name: '' });

  const masterCtx = useContext(MasterContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await masterCtx.update('categories', 'menu_category', id, form);

      toast.success('Successfully updated category!');
      navigate('/master/categories');
    } catch (error) {
      toast.error('Failed to update category!');
    }
  };

  useEffect(() => {
    const category = masterCtx.all.categories.find((category) => category.menu_category_id === id);

    setForm({ menu_category_name: category.menu_category_name });
  }, []);

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Master</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/master/categories">Categories</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit
          </li>
        </ol>
        <h2 className="main-title">Edit Category</h2>

        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="menu_category_name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="menu_category_name"
              value={form.menu_category_name}
              placeholder="Enter category name"
              required
              onChange={handleInputChange}
            />
          </Form.Group>

          <div className="d-flex gap-2">
            <Button variant="primary" type="submit" disabled={masterCtx.isLoading}>
              {masterCtx.isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Button
              variant="secondary"
              disabled={masterCtx.isLoading}
              onClick={() => navigate('/master/categories')}>
              Cancel
            </Button>
          </div>
        </Form>

        <Footer />
      </div>
    </>
  );
};

export default EditCategory;
