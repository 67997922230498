import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Grid, _ } from 'gridjs-react';

import { MasterContext } from 'contexts/master';
import ActionModal from 'components/ActionModal';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const CafeBranches = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  const navigate = useNavigate();
  const masterCtx = useContext(MasterContext);

  const handleDeleteClick = async () => {
    try {
      await masterCtx.destroy('branches', 'branch', deletingId);

      toast.success('Successfully deleted branch!');
    } catch (error) {
      toast.error('Failed to delete branch!');
    }

    setIsDeleting(false);
    setDeletingId(null);
  };

  return (
    <>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Master</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Cafe Branches
          </li>
        </ol>
        <h2 className="main-title">Cafe Branches</h2>

        {masterCtx.isInitializing ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Button
              variant="primary"
              className="mb-2 text-align-right"
              onClick={() => navigate('/master/cafe-branches/add')}>
              Add Branch
            </Button>

            <Grid
              columns={['No', 'Name', 'Address', 'Actions']}
              data={masterCtx.all.branches.map((branch, idx) => [
                idx + 1,
                branch.branch_name,
                branch.branch_address,
                _(
                  <div className="d-flex gap-2">
                    <Button
                      variant="warning"
                      disabled={masterCtx.isLoading}
                      onClick={() => navigate(`/master/cafe-branches/edit/${branch.branch_id}`)}>
                      <i className="ri-edit-line" />
                    </Button>
                    <Button
                      variant="danger"
                      disabled={masterCtx.isLoading}
                      onClick={() => {
                        setIsDeleting(true);
                        setDeletingId(branch.branch_id);
                      }}>
                      <i className="ri-delete-bin-line" />
                    </Button>
                  </div>
                ),
              ])}
              search
              pagination={{ limit: 10 }}
              sort
              resizable
              className={{ table: 'table table-bordered mb-0' }}
            />
          </>
        )}

        <ActionModal
          isOpen={isDeleting}
          onDismiss={() => setIsDeleting(false)}
          onConfirm={handleDeleteClick}
          isLoading={masterCtx.isLoading}
        />

        <Footer />
      </div>
    </>
  );
};

export default CafeBranches;
