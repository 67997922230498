import React from 'react';

import Forbidden from 'views/Forbidden';
import InternalServerError from 'views/InternalServerError';
import NotFound from 'views/NotFound';
import ServiceUnavailable from 'views/ServiceUnavailable';
import SignIn from 'views/auth/SignIn';
import ResetPassword from 'views/auth/ResetPassword';
import ChangePassword from 'views/auth/ChangePassword';

const publicRoutes = [
  { path: '/auth/login', element: <SignIn /> },
  { path: '/auth/reset-password', element: <ResetPassword /> },
  { path: '/auth/change-password', element: <ChangePassword /> },
  { path: '/error-404', element: <NotFound /> },
  { path: '/error-500', element: <InternalServerError /> },
  { path: '/error-503', element: <ServiceUnavailable /> },
  { path: '/error-505', element: <Forbidden /> },
];

export default publicRoutes;
