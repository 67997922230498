import { ClientPrintJob, InstalledPrinter, JSPrintManager, WSStatus } from 'jsprintmanager';
import moment from 'moment';

//WebSocket settings

//Check JSPM WebSocket status
export function jspmWSStatus() {
  if (JSPrintManager.websocket_status == WSStatus.Open) return true;
  else if (JSPrintManager.websocket_status == WSStatus.Closed) {
    alert(
      'JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm'
    );
    return false;
  } else if (JSPrintManager.websocket_status == WSStatus.Blocked) {
    alert('JSPM has blocked this website!');
    return false;
  }
}

//Do printing...
export function print(data, selectedPrinter) {
  if (jspmWSStatus()) {
    //Create a ClientPrintJob
    const cpj = new ClientPrintJob();
    //Set Printer type (Refer to the help, there many of them!)
    cpj.clientPrinter = new InstalledPrinter(selectedPrinter);

    //Set content to print...
    //Create ESP/POS commands for sample label
    const esc = '\x1B'; //ESC byte in hex notation
    const newLine = '\x0A'; //LF byte in hex notation

    let cmds = esc + '@'; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x38'; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
    cmds += data.data_order.branch_name; //text to print
    cmds += newLine;
    cmds += data.data_order.branch_address;
    cmds += newLine + newLine;
    cmds += `Order ID : ${data.data_order.order_id}`;
    cmds += newLine;
    cmds += `Date     : ${moment(data.data_order.created_at).format('LLL')}`;
    cmds += newLine;
    cmds += `Cashier  : ${data.data_order.name}`;
    cmds += newLine + newLine;
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    data.data_order_detail.map((item) => {
      cmds += `${item.cafe_menu_name} ${item.quantity} x ${new Intl.NumberFormat('id-ID').format(
        item.subtotal
      )}`;
      cmds += newLine;
    });
    cmds += newLine;
    cmds += `Subtotal             ${new Intl.NumberFormat('id-ID').format(
      data.data_order.subtotal
    )}`;
    cmds += newLine;
    cmds += `Tax                  ${new Intl.NumberFormat('id-ID').format(data.data_order.tax)}`;
    cmds += newLine;
    cmds += `Discount             ${new Intl.NumberFormat('id-ID').format(
      data.data_order.discount
    )}`;
    cmds += newLine;
    cmds += `Grand Total          ${new Intl.NumberFormat('id-ID').format(
      data.data_order.total_price
    )}`;
    cmds += newLine;
    cmds += `Payment Method       ${data.data_order.payment}`;
    cmds += newLine + newLine;
    cmds += esc + '!' + '\x18'; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
    cmds += 'Thank You for Your Order!';
    cmds += newLine + newLine;

    cpj.printerCommands = cmds;
    //Send print job to printer!
    cpj.sendToClient();
  }
}
