import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { AuthContext } from 'contexts/auth';
import { MasterContext } from 'contexts/master';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const AddCafeMenu = () => {
  const [form, setForm] = useState({
    cafe_menu_name: '',
    submenu_category_id: '',
    cafe_menu_ingredient: [{ ingredient: '', quantity: '' }],
    cafe_menu_price: '',
  });

  const authCtx = useContext(AuthContext);
  const masterCtx = useContext(MasterContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleSelectChange = (e, name) => {
    setForm({ ...form, [name]: e ? e.value : '' });
  };

  const handleIngredientSelectChange = (e, idx) => {
    const updatedIngredients = [...form.cafe_menu_ingredient];
    updatedIngredients[idx].ingredient = e ? e.value : '';

    setForm({ ...form, cafe_menu_ingredient: updatedIngredients });
  };

  const handleIngredientQtyInputChange = (e, idx) => {
    const updatedIngredients = [...form.cafe_menu_ingredient];
    updatedIngredients[idx].quantity = e.target.value;

    setForm({ ...form, cafe_menu_ingredient: updatedIngredients });
  };

  const handleUpdateIngredientField = (mode, idx) => {
    const updatedIngredients = [...form.cafe_menu_ingredient];

    switch (mode) {
      case 'add': {
        if (updatedIngredients.length === masterCtx.all.materials.length) return;

        updatedIngredients.push({ ingredient: '', quantity: '' });

        return setForm({ ...form, cafe_menu_ingredient: updatedIngredients });
      }
      case 'remove': {
        if (updatedIngredients.length === 1) return;

        updatedIngredients.splice(idx, 1);

        return setForm({ ...form, cafe_menu_ingredient: updatedIngredients });
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await masterCtx.create('menus', 'cafe_menu', { ...form, branch_id: authCtx.user.branch_id });

      toast.success('Successfully added menu!');
      navigate('/master/cafe-menus');
    } catch (error) {
      toast.error('Failed to add menu!');
    }
  };

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Master</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/master/cafe-menus">Cafe Menus</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add
          </li>
        </ol>
        <h2 className="main-title">Add Cafe Menu</h2>

        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="cafe_menu_name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="cafe_menu_name"
              value={form.cafe_menu_name}
              placeholder="Enter menu name"
              required
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="submenu_category_id">
            <Form.Label>Subcategory</Form.Label>
            <Select
              name="submenu_category_id"
              options={masterCtx.all.subcategories.map((subcategory) => ({
                value: subcategory.submenu_category_id,
                label: subcategory.submenu_category_name,
              }))}
              value={
                form.submenu_category_id && {
                  value: form.submenu_category_id,
                  label: masterCtx.all.subcategories.find(
                    (subcategory) => subcategory.submenu_category_id === form.submenu_category_id
                  )?.submenu_category_name,
                }
              }
              placeholder="Select subcategory"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#8c7851',
                  primary25: '#eaddcf',
                },
              })}
              isSearchable
              isClearable
              required
              onChange={(e) => handleSelectChange(e, 'submenu_category_id')}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="cafe_menu_ingredient">
            <Form.Label>Ingredients</Form.Label>
            {form.cafe_menu_ingredient.map((ingredient, idx) => (
              <div key={`ingredient-${idx + 1}`} className="d-flex gap-2 mb-2">
                <Select
                  name="ingredient"
                  options={masterCtx.all.materials.map((material) => ({
                    value: material.material_id,
                    label: material.material_name,
                    isDisabled: !!form.cafe_menu_ingredient.find(
                      (ingredient) => ingredient.ingredient === material.material_id
                    ),
                  }))}
                  value={
                    ingredient.ingredient && {
                      value: ingredient.ingredient,
                      label: masterCtx.all.materials.find(
                        (material) => material.material_id === ingredient.ingredient
                      )?.material_name,
                    }
                  }
                  placeholder="Select ingredient name"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: '#8c7851',
                      primary25: '#eaddcf',
                    },
                  })}
                  isSearchable
                  isClearable
                  required
                  className="w-100"
                  onChange={(e) => handleIngredientSelectChange(e, idx)}
                />
                <InputGroup>
                  {form.cafe_menu_ingredient[idx].ingredient && (
                    <InputGroup.Text>
                      {masterCtx.all.measurements.find(
                        (measurement) =>
                          measurement.measurement_id ===
                          masterCtx.all.materials.find(
                            (material) =>
                              material.material_id === form.cafe_menu_ingredient[idx].ingredient
                          )?.measurement_to
                      )?.measurement_name || 'N/A'}
                    </InputGroup.Text>
                  )}
                  <Form.Control
                    type="number"
                    name="quantity"
                    value={ingredient.quantity}
                    placeholder="Enter ingredient quantity"
                    required
                    onChange={(e) => handleIngredientQtyInputChange(e, idx)}
                  />
                </InputGroup>
                <Button
                  variant="danger"
                  disabled={masterCtx.isLoading || form.cafe_menu_ingredient.length === 1}
                  onClick={() => handleUpdateIngredientField('remove')}>
                  <i className="ri-subtract-line" />
                </Button>
              </div>
            ))}
            <Button
              variant="outline-primary"
              disabled={
                masterCtx.isLoading ||
                form.cafe_menu_ingredient.length === masterCtx.all.materials.length
              }
              className="w-100"
              onClick={() => handleUpdateIngredientField('add')}>
              Add Another Ingredient
            </Button>
          </Form.Group>

          <Form.Group className="mb-3" controlId="cafe_menu_price">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              name="cafe_menu_price"
              value={form.cafe_menu_price}
              placeholder="Enter menu price"
              required
              onChange={handleInputChange}
            />
          </Form.Group>

          <div className="d-flex gap-2">
            <Button variant="primary" type="submit" disabled={masterCtx.isLoading}>
              {masterCtx.isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Button
              variant="secondary"
              disabled={masterCtx.isLoading}
              onClick={() => navigate('/master/cafe-menus')}>
              Cancel
            </Button>
          </div>
        </Form>

        <Footer />
      </div>
    </>
  );
};

export default AddCafeMenu;
