import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { AuthContext } from 'contexts/auth';
import { MasterContext } from 'contexts/master';
import { UserContext } from 'contexts/user';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const EditUser = () => {
  const { id } = useParams();

  const [form, setForm] = useState({ name: '', email: '', privilege_id: '' });

  const authCtx = useContext(AuthContext);
  const masterCtx = useContext(MasterContext);
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleSelectChange = (e, name) => {
    setForm({ ...form, [name]: e ? e.value : '' });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await userCtx.update(id, { ...form, branch_id: authCtx.user.branch_id });

      toast.success('Successfully updated user!');
      navigate('/user/users');
    } catch (error) {
      toast.error('Failed to update user!');
    }
  };

  useEffect(() => {
    const user = userCtx.all.users.find((user) => user.username === id);

    setForm({
      name: user.name,
      email: user.email,
      level_id: user.level_id,
      privilege_id: user.privilege_id,
    });
  }, []);

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">User</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/user/users">Users</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit
          </li>
        </ol>
        <h2 className="main-title">Edit User</h2>

        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={form.name}
              placeholder="Enter user name"
              required
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={form.email}
              placeholder="Enter user email"
              required
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="privilege_id">
            <Form.Label>Privilege</Form.Label>
            <Select
              name="privilege_id"
              options={masterCtx.all.privileges.map((privilege) => ({
                value: privilege.privilege_id,
                label: privilege.privilege_name,
              }))}
              value={
                form.privilege_id && {
                  value: form.privilege_id,
                  label: masterCtx.all.privileges.find(
                    (privilege) => privilege.privilege_id === form.privilege_id
                  )?.privilege_name,
                }
              }
              placeholder="Select user privilege"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#8c7851',
                  primary25: '#eaddcf',
                },
              })}
              isSearchable
              isClearable
              required
              onChange={(e) => handleSelectChange(e, 'privilege_id')}
            />
          </Form.Group>

          <div className="d-flex gap-2">
            <Button variant="primary" type="submit" disabled={userCtx.isLoading}>
              {userCtx.isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Button
              variant="secondary"
              disabled={userCtx.isLoading}
              onClick={() => navigate('/user/users')}>
              Cancel
            </Button>
          </div>
        </Form>

        <Footer />
      </div>
    </>
  );
};

export default EditUser;
