import { useContext, useEffect, useState } from 'react';

import { MasterContext } from './MasterContext';
import { BASE_API_URL } from 'constants/api';
import { AuthContext } from 'contexts/auth';
import { deleteFetcher, getFetcher, postFetcher, putFetcher } from 'utils/fetcher';
import { getToken } from 'utils/storeToken';

export const MasterProvider = ({ children }) => {
  const [all, setAll] = useState({
    privileges: [],
    categories: [],
    subcategories: [],
    menus: [],
    branches: [],
    materials: [],
    measurements: [],
  });
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);

  const token = getToken();

  const create = async (name, slug, payload) => {
    try {
      setIsLoading(true);

      const response = await postFetcher(
        `${BASE_API_URL}/${slug}/store`,
        payload,
        token,
        authCtx.user?.privilege_id
      );

      setAll({ ...all, [name]: [...all[name], response.data.data] });
    } catch (error) {
      throw new Error('Failed to add data!');
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (name, slug, id, payload) => {
    try {
      setIsLoading(true);

      const response = await putFetcher(
        `${BASE_API_URL}/${slug}/update/${id}`,
        payload,
        token,
        authCtx.user?.privilege_id
      );

      setAll({
        ...all,
        [name]: all[name].map((item) => {
          if (item[`${slug}_id`] === id) {
            return response.data.data;
          }

          return item;
        }),
      });
    } catch (error) {
      throw new Error('Failed to update data!');
    } finally {
      setIsLoading(false);
    }
  };

  const destroy = async (name, slug, id) => {
    try {
      setIsLoading(true);

      await deleteFetcher(
        `${BASE_API_URL}/${slug}/delete/${id}`,
        token,
        authCtx.user?.privilege_id
      );

      setAll({
        ...all,
        [name]: all[name].filter((item) => item[`${slug}_id`] !== id),
      });
    } catch (error) {
      throw new Error('Failed to delete data!');
    } finally {
      setIsLoading(false);
    }
  };

  const detail = async (slug, id) => {
    try {
      setIsLoading(true);

      const response = await getFetcher(
        `${BASE_API_URL}/${slug}?id=${id}`,
        token,
        authCtx.user?.privilege_id
      );

      return response.data;
    } catch (error) {
      throw new Error('Failed to get data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getAll = async () => {
      if (token && authCtx.user) {
        try {
          const privilegesResponse =
            authCtx.user?.privilege_id === 'P2300001'
              ? await getFetcher(`${BASE_API_URL}/privilege`, token, authCtx.user?.privilege_id)
              : [];
          const categoriesResponse = await getFetcher(
            `${BASE_API_URL}/menu_category`,
            token,
            authCtx.user?.privilege_id
          );
          const subcategoriesResponse = await getFetcher(
            `${BASE_API_URL}/submenu_category`,
            token,
            authCtx.user?.privilege_id
          );
          const menusResponse = await getFetcher(
            `${BASE_API_URL}/cafe_menu`,
            token,
            authCtx.user?.privilege_id
          );
          const branchesResponse = await getFetcher(
            `${BASE_API_URL}/branch`,
            token,
            authCtx.user?.privilege_id
          );
          const materialsResponse = await getFetcher(
            `${BASE_API_URL}/material`,
            token,
            authCtx.user?.privilege_id
          );
          const measurementsResponse = await getFetcher(
            `${BASE_API_URL}/measurement`,
            token,
            authCtx.user?.privilege_id
          );

          setAll({
            privileges: privilegesResponse.data,
            categories: categoriesResponse.data,
            subcategories: subcategoriesResponse.data,
            menus: menusResponse.data,
            branches: branchesResponse.data,
            materials: materialsResponse.data,
            measurements: measurementsResponse.data,
          });
        } catch (error) {
          return new Error('Failed to get data!');
        } finally {
          setIsInitializing(false);
        }
      }
    };

    getAll();
  }, [token, authCtx.user]);

  return (
    <MasterContext.Provider
      value={{ all, isInitializing, isLoading, create, update, destroy, detail }}>
      {children}
    </MasterContext.Provider>
  );
};
