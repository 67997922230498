import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { MasterContext } from 'contexts/master';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const EditSubcategory = () => {
  const { id } = useParams();

  const [form, setForm] = useState({ submenu_category_name: '', menu_category_id: '' });

  const masterCtx = useContext(MasterContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleSelectChange = (e, name) => {
    setForm({ ...form, [name]: e ? e.value : '' });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await masterCtx.update('subcategories', 'submenu_category', id, form);

      toast.success('Successfully updated subcategory!');
      navigate('/master/subcategories');
    } catch (error) {
      toast.error('Failed to update subcategory!');
    }
  };

  useEffect(() => {
    const subcategory = masterCtx.all.subcategories.find(
      (subcategory) => subcategory.submenu_category_id === id
    );

    setForm({
      submenu_category_name: subcategory.submenu_category_name,
      menu_category_id: subcategory.menu_category_id,
    });
  }, []);

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Master</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/master/subcategories">Subcategories</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit
          </li>
        </ol>
        <h2 className="main-title">Edit Subcategory</h2>

        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="submenu_category_name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="submenu_category_name"
              value={form.submenu_category_name}
              placeholder="Enter subcategory name"
              required
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="menu_category_id">
            <Form.Label>Category</Form.Label>
            <Select
              name="menu_category_id"
              options={masterCtx.all.categories.map((category) => ({
                value: category.menu_category_id,
                label: category.menu_category_name,
              }))}
              value={
                form.menu_category_id && {
                  value: form.menu_category_id,
                  label: masterCtx.all.categories.find(
                    (category) => category.menu_category_id === form.menu_category_id
                  )?.menu_category_name,
                }
              }
              placeholder="Select category"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#8c7851',
                  primary25: '#eaddcf',
                },
              })}
              isSearchable
              isClearable
              required
              onChange={(e) => handleSelectChange(e, 'menu_category_id')}
            />
          </Form.Group>

          <div className="d-flex gap-2">
            <Button variant="primary" type="submit" disabled={masterCtx.isLoading}>
              {masterCtx.isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Button
              variant="secondary"
              disabled={masterCtx.isLoading}
              onClick={() => navigate('/master/subcategories')}>
              Cancel
            </Button>
          </div>
        </Form>

        <Footer />
      </div>
    </>
  );
};

export default EditSubcategory;
