import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Grid, _ } from 'gridjs-react';
import moment from 'moment';

import { InventoryContext } from 'contexts/inventory';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const InventoryDetail = () => {
  const { id } = useParams();

  const [detail, setDetail] = useState(null);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);

  const navigate = useNavigate();

  const inventoryCtx = useContext(InventoryContext);

  useEffect(() => {
    const getDetail = async () => {
      try {
        setIsLoadingDetail(true);

        const data = await inventoryCtx.detail(id);

        setDetail(data);
      } catch (error) {
        toast.error('Failed to fetch inventory detail!');
      } finally {
        setIsLoadingDetail(false);
      }
    };

    getDetail();
  }, []);

  return (
    <>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Inventory</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/inventory/inventories">Inventories</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Details
          </li>
        </ol>
        <h2 className="main-title">Inventory Details</h2>

        {isLoadingDetail || !detail ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className="mb-4">
              <h5>Material Details</h5>
              <Grid
                data={[
                  ['Name', detail.material_name],
                  ['UOM', detail.measurement_name],
                ]}
                resizable
                className={{ table: 'table table-bordered mb-0' }}
              />
            </div>

            <div>
              <h5>Material In & Out</h5>
              <Button
                variant="primary"
                className="mb-3"
                onClick={() => navigate(`/inventory/inventories/${detail.material_id}/add`)}>
                Add Material In & Out
              </Button>
              <Grid
                columns={['No', 'Date Received', 'Quantity', 'Action']}
                data={inventoryCtx.all.inventories
                  .filter((inventory) => inventory.material_id === detail.material_id)
                  .map((inventory, idx) => [
                    idx + 1,
                    moment(inventory.date_received).format('LLL'),
                    inventory.quantity + ' ' + detail.measurement_name,
                    _(
                      <Button
                        variant="warning"
                        onClick={() =>
                          navigate(`/inventory/inventories/${inventory.stock_id}/edit`)
                        }>
                        <i className="ri-edit-line" />
                      </Button>
                    ),
                  ])}
                search
                pagination={{ limit: 10 }}
                sort
                resizable
                className={{ table: 'table table-bordered mb-0' }}
              />
            </div>
          </>
        )}

        <Footer />
      </div>
    </>
  );
};

export default InventoryDetail;
