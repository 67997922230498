import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Row,
  Spinner,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
// import { useReactToPrint } from 'react-to-print';
import { Grid, _ } from 'gridjs-react';
import { JSPrintManager } from 'jsprintmanager';
import Select from 'react-select';

import { AuthContext } from 'contexts/auth';
import { MasterContext } from 'contexts/master';
import { OrderContext } from 'contexts/order';
import { jspmWSStatus, print } from 'utils/printReceipt';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';
// import OrderReceipt from 'components/OrderReceipt';

const Cashier = () => {
  const [form, setForm] = useState({
    payment: 'Cash',
    subtotal: 0,
    tax: 0,
    discount: 0,
    total_price: 0,
    order_detail: [],
  });
  const [filteredMenus, setFilteredMenus] = useState([]);
  const [recent, setRecent] = useState([]);
  // const [detail, setDetail] = useState(null);
  const [isActivateTax, setIsActivateTax] = useState(false);
  const [isActivateDiscount, setIsActivateDiscount] = useState(false);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);
  const [installedPrinters, setInstalledPrinters] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState('');
  const [isLoadingRecent, setIsLoadingRecent] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);

  const authCtx = useContext(AuthContext);
  const masterCtx = useContext(MasterContext);
  const orderCtx = useContext(OrderContext);

  // const receiptRef = useRef();

  // const handlePrintReceipt = useReactToPrint({
  //   content: () => receiptRef.current,
  //   onAfterPrint: () => setDetail(null),
  // });

  const handleQtyButtonClick = (id, price, operator) => {
    const currentOrder = form.order_detail.find((order) => order.cafe_menu_id === id);
    let updatedOrders = [];

    switch (operator) {
      case '+': {
        updatedOrders = currentOrder
          ? form.order_detail.map((order) => {
              if (order.cafe_menu_id === id) {
                return {
                  ...order,
                  quantity: order.quantity + 1,
                  subtotal: order.subtotal + price,
                };
              }
              return order;
            })
          : [...form.order_detail, { cafe_menu_id: id, quantity: 1, subtotal: price }];
        break;
      }
      case '-': {
        updatedOrders =
          currentOrder && currentOrder.quantity === 1
            ? form.order_detail.filter((order) => order.cafe_menu_id !== id)
            : form.order_detail.map((order) => {
                if (order.cafe_menu_id === id) {
                  return {
                    ...order,
                    quantity: order.quantity - 1,
                    subtotal: order.subtotal - price,
                  };
                }
                return order;
              });
        break;
      }
    }

    setForm({
      ...form,
      subtotal: Math.round(updatedOrders.reduce((acc, curr) => acc + curr.subtotal, 0)),
      tax: Math.round(
        updatedOrders.reduce((acc, curr) => acc + curr.subtotal, 0) * (taxPercentage / 100)
      ),
      discount: -Math.abs(
        Math.round(
          updatedOrders.reduce((acc, curr) => acc + curr.subtotal, 0) * (discountPercentage / 100)
        )
      ),
      total_price: Math.round(
        updatedOrders.reduce((acc, curr) => acc + curr.subtotal, 0) +
          updatedOrders.reduce((acc, curr) => acc + curr.subtotal, 0) * (taxPercentage / 100) -
          updatedOrders.reduce((acc, curr) => acc + curr.subtotal, 0) * (discountPercentage / 100)
      ),
      order_detail: updatedOrders,
    });
  };

  const handleQtyInputChange = (e, id, price) => {
    e.preventDefault();

    const { value } = e.target;

    const currentOrder = form.order_detail.find((order) => order.cafe_menu_id === id);
    const updatedOrders = currentOrder
      ? Number(value) === 0
        ? form.order_detail.filter((order) => order.cafe_menu_id !== id)
        : form.order_detail.map((order) => {
            if (order.cafe_menu_id === id) {
              return {
                ...order,
                quantity: Number(value),
                subtotal: price * Number(value),
              };
            }
            return order;
          })
      : [
          ...form.order_detail,
          { cafe_menu_id: id, quantity: Number(value), subtotal: price * Number(value) },
        ];

    setForm({
      ...form,
      subtotal: Math.round(updatedOrders.reduce((acc, curr) => acc + curr.subtotal, 0)),
      tax: Math.round(
        updatedOrders.reduce((acc, curr) => acc + curr.subtotal, 0) * (taxPercentage / 100)
      ),
      discount: -Math.abs(
        Math.round(
          updatedOrders.reduce((acc, curr) => acc + curr.subtotal, 0) * (discountPercentage / 100)
        )
      ),
      total_price: Math.round(
        updatedOrders.reduce((acc, curr) => acc + curr.subtotal, 0) +
          updatedOrders.reduce((acc, curr) => acc + curr.subtotal, 0) * (taxPercentage / 100) -
          updatedOrders.reduce((acc, curr) => acc + curr.subtotal, 0) * (discountPercentage / 100)
      ),
      order_detail: updatedOrders,
    });
  };

  const handlePaymentChange = (value) => {
    setForm({ ...form, payment: value });
    setCashAmount(0);
  };

  const handleCheckout = async () => {
    try {
      await orderCtx.create({
        ...form,
        cashier_id: authCtx.user.username,
        branch: authCtx.user.branch_id,
      });

      setIsLoadingRecent(true);
      const recentOrders = await orderCtx.recent();

      setRecent(recentOrders);
      toast.success('Successfully added order!');
    } catch (error) {
      toast.error('Failed to add order!');
    } finally {
      setForm({
        payment: 'Cash',
        subtotal: 0,
        tax: 0,
        discount: 0,
        total_price: 0,
        order_detail: [],
      });
      setCashAmount(0);
      setIsLoadingRecent(false);
    }
  };

  const handleGetOrderDetail = async (id) => {
    try {
      setIsLoadingDetail(true);

      const data = await orderCtx.detail(id);

      print(data, selectedPrinter);
    } catch (error) {
      toast.error('Failed to fetch order detail!');
    } finally {
      setIsLoadingDetail(false);
    }
  };

  useEffect(() => {
    const getRecentOrders = async () => {
      try {
        setIsLoadingRecent(true);

        const data = await orderCtx.recent();

        setRecent(data);
      } catch (error) {
        toast.error('Failed to fetch recent orders!');
      } finally {
        setIsLoadingRecent(false);
      }
    };

    const getInstalledPrinters = () => {
      JSPrintManager.auto_reconnect = true;
      JSPrintManager.start();
      JSPrintManager.WS.onStatusChanged = () => {
        if (jspmWSStatus()) {
          //get client installed printers
          JSPrintManager.getPrinters().then((myPrinters) => {
            setInstalledPrinters(myPrinters);
          });
        }
      };
    };

    getRecentOrders();
    getInstalledPrinters();
  }, []);

  useEffect(() => {
    setFilteredMenus(masterCtx.all.menus);
  }, [masterCtx.isInitializing]);

  // useEffect(() => {
  //   !!detail && handlePrintReceipt();
  // }, [detail]);

  return (
    <>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        {masterCtx.isInitializing || orderCtx.isInitializing || isLoadingRecent ? (
          <>
            <ol className="breadcrumb fs-sm mb-2">
              <li className="breadcrumb-item">
                <Link to="#">Sales</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Kasir
              </li>
            </ol>
            <h2 className="main-title">Kasir</h2>

            <div className="text-center">
              <Spinner animation="border" variant="primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </>
        ) : (
          <Row className="g-5">
            <Col xl={8} md={12}>
              <ol className="breadcrumb fs-sm mb-2">
                <li className="breadcrumb-item">
                  <Link to="#">Sales</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Kasir
                </li>
              </ol>
              <h2 className="main-title">Kasir</h2>

              <Row>
                <Col className="mb-4">
                  <Select
                    placeholder="Cari menu"
                    options={filteredMenus.map((menu) => ({
                      value: menu.cafe_menu_id,
                      label: menu.cafe_menu_name,
                    }))}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#8c7851',
                        primary25: '#eaddcf',
                      },
                    })}
                    isClearable
                    isSearchable
                    className="mb-3"
                    onChange={(value) => {
                      setFilteredMenus(
                        value
                          ? filteredMenus.filter((menu) => menu.cafe_menu_id === value.value)
                          : filteredMenus
                      );
                    }}
                  />
                  <ListGroup>
                    {filteredMenus.map((menu) => (
                      <ListGroup.Item key={menu.cafe_menu_id}>
                        <Row>
                          <Col>
                            <Card.Title className="my-2">{menu.cafe_menu_name}</Card.Title>
                            <Card.Subtitle className="text-muted my-2">
                              {new Intl.NumberFormat('id-ID', {
                                style: 'currency',
                                currency: 'IDR',
                              }).format(menu.cafe_menu_price)}
                            </Card.Subtitle>
                          </Col>
                          <Col className="d-flex justify-content-end align-items-center gap-2">
                            <Button
                              variant="danger"
                              disabled={
                                !form.order_detail.find(
                                  (order) => order.cafe_menu_id === menu.cafe_menu_id
                                )
                              }
                              onClick={() =>
                                handleQtyButtonClick(
                                  menu.cafe_menu_id,
                                  Number(menu.cafe_menu_price),
                                  '-'
                                )
                              }>
                              <i className="ri-subtract-line" />
                            </Button>
                            <Form.Group controlId="quantity">
                              <Form.Control
                                type="number"
                                name="quantity"
                                value={
                                  form.order_detail.find(
                                    (order) => order.cafe_menu_id === menu.cafe_menu_id
                                  )?.quantity || 0
                                }
                                onChange={(e) =>
                                  handleQtyInputChange(
                                    e,
                                    menu.cafe_menu_id,
                                    Number(menu.cafe_menu_price)
                                  )
                                }
                              />
                            </Form.Group>
                            <Button
                              variant="success"
                              onClick={() =>
                                handleQtyButtonClick(
                                  menu.cafe_menu_id,
                                  Number(menu.cafe_menu_price),
                                  '+'
                                )
                              }>
                              <i className="ri-add-line" />
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>
              </Row>
            </Col>

            <Col xl={4} md={12}>
              <h5 className="mb-2">Pembayaran</h5>

              <hr />

              <div className="mb-3">
                <h5 className="section-title">Detail Menu</h5>
                <Grid
                  columns={['Menu', 'Jumlah', 'Harga']}
                  data={form.order_detail.map((order) => [
                    filteredMenus.find((menu) => menu.cafe_menu_id === order.cafe_menu_id)
                      .cafe_menu_name,
                    order.quantity,
                    new Intl.NumberFormat('id-ID', {
                      style: 'currency',
                      currency: 'IDR',
                    }).format(order.subtotal),
                  ])}
                  resizable
                  className={{ table: 'table table-bordered mb-0' }}
                />
              </div>

              <div className="mb-3">
                <Form.Check
                  type="switch"
                  label="Aktifkan Pajak"
                  value={isActivateTax}
                  className="mb-2"
                  onChange={(e) => setIsActivateTax(e.target.checked)}
                />
                {isActivateTax && (
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      name="tax"
                      value={taxPercentage === 0 ? '' : taxPercentage}
                      placeholder="Masukkan persentase pajak"
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        setTaxPercentage(value);
                        setForm((prev) => ({
                          ...prev,
                          tax: Math.round(prev.subtotal * (value / 100)),
                          total_price: Math.round(
                            prev.subtotal +
                              prev.subtotal * (value / 100) -
                              prev.subtotal * (discountPercentage / 100)
                          ),
                        }));
                      }}
                      required
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                )}
                <Form.Check
                  type="switch"
                  label="Aktifkan Diskon"
                  value={isActivateDiscount}
                  className="mb-2"
                  onChange={(e) => setIsActivateDiscount(e.target.checked)}
                />
                {isActivateDiscount && (
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      name="discount"
                      value={discountPercentage === 0 ? '' : discountPercentage}
                      placeholder="Masukkan persentase diskon"
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        setDiscountPercentage(value);
                        setForm((prev) => ({
                          ...prev,
                          discount: -Math.abs(Math.round(prev.subtotal * (value / 100))),
                          total_price: Math.round(
                            prev.subtotal +
                              prev.subtotal * (taxPercentage / 100) -
                              prev.subtotal * (value / 100)
                          ),
                        }));
                      }}
                      required
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                )}
              </div>

              <div className="mb-3">
                <h5 className="section-title">Detail Harga</h5>
                <Grid
                  data={[
                    {
                      label: 'Subtotal',
                      value: `${new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                      }).format(form.subtotal)}`,
                    },
                    {
                      label: `Pajak ${taxPercentage}%`,
                      value: `${new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                      }).format(form.tax)}`,
                    },
                    {
                      label: `Diskon ${discountPercentage}%`,
                      value: `${new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                      }).format(form.discount)}`,
                    },

                    {
                      label: 'Total Harga',
                      value: `${new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                      }).format(form.total_price)}`,
                    },
                  ]
                    .filter((item) => {
                      if (item.label === 'Discount' && !isActivateDiscount) return false;
                      if (item.label === 'Tax' && !isActivateTax) return false;
                      return true;
                    })
                    .map((item) => [item.label, item.value])}
                  resizable
                  className={{ table: 'table table-bordered mb-0' }}
                />
              </div>

              <div className="mb-3">
                <h5 className="section-title">Metode Pembayaran</h5>
                <ToggleButtonGroup
                  type="radio"
                  name="payment"
                  value={form.payment}
                  className="d-flex"
                  onChange={(value) => handlePaymentChange(value)}>
                  <ToggleButton
                    id="cash"
                    value="Cash"
                    variant={form.payment === 'Cash' ? 'primary' : 'outline-primary'}
                    className="w-100">
                    Tunai
                  </ToggleButton>
                  <ToggleButton
                    id="debit"
                    value="Debit"
                    variant={form.payment === 'Debit' ? 'primary' : 'outline-primary'}
                    className="w-100">
                    Debit / E-Wallet
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>

              {form.payment === 'Cash' && (
                <Form.Group className="mb-3" controlId="cash_amount">
                  <Form.Control
                    type="number"
                    name="cash_amount"
                    value={cashAmount === 0 ? '' : cashAmount}
                    placeholder="Masukkan jumlah uang tunai"
                    required
                    isValid={
                      form.order_detail.length > 0 &&
                      cashAmount > 0 &&
                      cashAmount >= form.total_price
                    }
                    isInvalid={
                      form.order_detail.length > 0 &&
                      cashAmount > 0 &&
                      cashAmount < form.total_price
                    }
                    onChange={(e) => setCashAmount(e.target.value)}
                  />
                  <Form.Control.Feedback
                    type={cashAmount >= form.total_price ? 'valid' : 'invalid'}>
                    {new Intl.NumberFormat('id-ID', {
                      style: 'currency',
                      currency: 'IDR',
                    }).format(cashAmount)}
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Button
                variant="primary"
                className="w-100 mb-3"
                disabled={
                  orderCtx.isLoading ||
                  form.total_price < 0 ||
                  form.order_detail.length === 0 ||
                  (form.payment === 'Cash' && cashAmount < form.total_price)
                }
                onClick={handleCheckout}>
                Pembayaran
              </Button>

              <h5 className="my-2">Riwayat Pesanan</h5>

              <hr />

              <Select
                options={installedPrinters.map((printer) => ({ value: printer, label: printer }))}
                value={selectedPrinter && { value: selectedPrinter, label: selectedPrinter }}
                placeholder="Pilih printer"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#8c7851',
                    primary25: '#eaddcf',
                  },
                })}
                isClearable
                isSearchable
                className="mb-3"
                onChange={(e) => setSelectedPrinter(e ? e.value : '')}
              />

              <Grid
                columns={['Order', 'Total', 'Aksi']}
                data={recent.map((order) => [
                  order.order_id,
                  new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  }).format(order.total_price),
                  _(
                    <Button
                      variant="primary"
                      disabled={isLoadingDetail || !selectedPrinter}
                      onClick={() => handleGetOrderDetail(order.order_id)}>
                      <i className="ri-printer-line" />
                    </Button>
                  ),
                ])}
                pagination={{ limit: 5 }}
                sort
                resizable
                className={{ table: 'table table-bordered mb-0' }}
              />

              {/* <div className="d-none">
                <OrderReceipt ref={receiptRef} data={detail} />
              </div> */}
            </Col>
          </Row>
        )}

        <Footer />
      </div>
    </>
  );
};

export default Cashier;
