import { useContext, useEffect, useState } from 'react';

import { InventoryContext } from './InventoryContext';
import { BASE_API_URL } from 'constants/api';
import { AuthContext } from 'contexts/auth';
import { getFetcher, postFetcher, putFetcher } from 'utils/fetcher';
import { getToken } from 'utils/storeToken';

export const InventoryProvider = ({ children }) => {
  const [all, setAll] = useState({ inventories: [], materials: [] });
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);

  const token = getToken();

  const create = async (payload) => {
    try {
      setIsLoading(true);

      const response = await postFetcher(
        `${BASE_API_URL}/inventory/store`,
        payload,
        token,
        authCtx.user?.privilege_id
      );

      setAll({ ...all, inventories: [...all.inventories, response.data.data] });
    } catch (error) {
      throw new Error('Failed to add inventory!');
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (id, payload) => {
    try {
      setIsLoading(true);

      const response = await putFetcher(
        `${BASE_API_URL}/inventory/update/${id}`,
        payload,
        token,
        authCtx.user?.privilege_id
      );

      setAll({
        ...all,
        inventories: all.inventories.map((item) => {
          if (item.stock_id === id) {
            return response.data.data;
          }

          return item;
        }),
      });
    } catch (error) {
      throw new Error('Failed to update inventory!');
    } finally {
      setIsLoading(false);
    }
  };

  const detail = async (id) => {
    try {
      setIsLoading(true);

      const response = await getFetcher(
        `${BASE_API_URL}/inventory/material?id=${id}`,
        token,
        authCtx.user?.privilege_id
      );

      return response.data;
    } catch (error) {
      throw new Error('Failed to get inventory detail!');
    } finally {
      setIsLoading(false);
    }
  };

  const detailEdit = async (id) => {
    try {
      setIsLoading(true);

      const response = await getFetcher(
        `${BASE_API_URL}/inventory/edit?id=${id}`,
        token,
        authCtx.user?.privilege_id
      );

      return response.data;
    } catch (error) {
      throw new Error('Failed to get inventory detail!');
    } finally {
      setIsLoading(false);
    }
  };

  const dashboard = async (slug) => {
    try {
      setIsLoading(true);

      const response = await getFetcher(
        `${BASE_API_URL}/inventory/${slug}`,
        token,
        authCtx.user?.privilege_id
      );

      return response.data;
    } catch (error) {
      throw new Error('Failed to get inventory dashboard!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getAll = async () => {
      if (token && authCtx.user) {
        try {
          const inventoriesResponse = await getFetcher(
            `${BASE_API_URL}/inventory`,
            token,
            authCtx.user?.privilege_id
          );
          const materialsResponse = await getFetcher(
            `${BASE_API_URL}/inventory/material`,
            token,
            authCtx.user?.privilege_id
          );

          setAll({ inventories: inventoriesResponse.data, materials: materialsResponse.data });
        } catch (error) {
          return new Error('Failed to get inventories!');
        } finally {
          setIsInitializing(false);
        }
      }
    };

    getAll();
  }, [token, authCtx.user]);

  return (
    <InventoryContext.Provider
      value={{ all, isInitializing, isLoading, create, update, detail, detailEdit, dashboard }}>
      {children}
    </InventoryContext.Provider>
  );
};
