import { useContext, useEffect, useState } from 'react';

import { OrderContext } from './OrderContext';
import { BASE_API_URL } from 'constants/api';
import { AuthContext } from 'contexts/auth';
import { getFetcher, postFetcher } from 'utils/fetcher';
import { getToken } from 'utils/storeToken';

export const OrderProvider = ({ children }) => {
  const [all, setAll] = useState({ orders: [] });
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);

  const token = getToken();

  const create = async (payload) => {
    try {
      setIsLoading(true);

      const response = await postFetcher(
        `${BASE_API_URL}/order/store`,
        payload,
        token,
        authCtx.user?.privilege_id
      );

      setAll({ ...all, orders: [...all.orders, response.data.data.order] });
    } catch (error) {
      throw new Error('Failed to add order!');
    } finally {
      setIsLoading(false);
    }
  };

  const detail = async (id) => {
    try {
      setIsLoading(true);

      const response = await getFetcher(
        `${BASE_API_URL}/order?id=${id}`,
        token,
        authCtx.user?.privilege_id
      );

      return response.data;
    } catch (error) {
      throw new Error('Failed to get order detail!');
    } finally {
      setIsLoading(false);
    }
  };

  const recent = async () => {
    try {
      setIsLoading(true);

      const response = await getFetcher(
        `${BASE_API_URL}/order/recent`,
        token,
        authCtx.user?.privilege_id
      );

      return response.data;
    } catch (error) {
      throw new Error('Failed to get recent orders!');
    } finally {
      setIsLoading(false);
    }
  };

  const dashboard = async (slug) => {
    try {
      setIsLoading(true);

      const response = await getFetcher(
        `${BASE_API_URL}/order/${slug}`,
        token,
        authCtx.user?.privilege_id
      );

      return response.data;
    } catch (error) {
      throw new Error('Failed to get order dashboard!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getAll = async () => {
      if (token && authCtx.user) {
        try {
          const ordersResponse = await getFetcher(
            `${BASE_API_URL}/order`,
            token,
            authCtx.user?.privilege_id
          );

          setAll({ orders: ordersResponse.data });
        } catch (error) {
          return new Error('Failed to get orders!');
        } finally {
          setIsInitializing(false);
        }
      }
    };

    getAll();
  }, [token, authCtx.user]);

  return (
    <OrderContext.Provider
      value={{ all, isInitializing, isLoading, create, detail, recent, dashboard }}>
      {children}
    </OrderContext.Provider>
  );
};
