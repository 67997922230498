import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { Grid, _ } from 'gridjs-react';

import { InventoryContext } from 'contexts/inventory';
import { MasterContext } from 'contexts/master';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const Inventories = () => {
  const navigate = useNavigate();

  const inventoryCtx = useContext(InventoryContext);
  const masterCtx = useContext(MasterContext);

  return (
    <>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Inventory</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Inventories
          </li>
        </ol>
        <h2 className="main-title">Inventories</h2>

        {masterCtx.isInitializing || inventoryCtx.isInitializing ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Grid
            columns={['No', 'Material Name', 'Action']}
            data={masterCtx.all.materials.map((material, idx) => [
              idx + 1,
              material.material_name,
              _(
                <Button
                  variant="primary"
                  onClick={() => navigate(`/inventory/inventories/${material.material_id}`)}>
                  <i className="ri-information-line" />
                </Button>
              ),
            ])}
            search
            pagination={{ limit: 10 }}
            sort
            resizable
            className={{ table: 'table table-bordered mb-0' }}
          />
        )}

        <Footer />
      </div>
    </>
  );
};

export default Inventories;
