import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { MasterContext } from 'contexts/master';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const AddPrivilege = () => {
  const [form, setForm] = useState({ privilege_name: '' });

  const masterCtx = useContext(MasterContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await masterCtx.create('privileges', 'privilege', form);

      toast.success('Successfully added privilege!');
      navigate('/master/privileges');
    } catch (error) {
      toast.error('Failed to add privilege!');
    }
  };

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Master</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/master/privileges">Privileges</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Add
          </li>
        </ol>
        <h2 className="main-title">Add Privilege</h2>

        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="privilege_name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="privilege_name"
              value={form.privilege_name}
              placeholder="Enter privilege name"
              required
              onChange={handleInputChange}
            />
          </Form.Group>

          <div className="d-flex gap-2">
            <Button variant="primary" type="submit" disabled={masterCtx.isLoading}>
              {masterCtx.isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Button
              variant="secondary"
              disabled={masterCtx.isLoading}
              onClick={() => navigate('/master/privileges')}>
              Cancel
            </Button>
          </div>
        </Form>

        <Footer />
      </div>
    </>
  );
};

export default AddPrivilege;
