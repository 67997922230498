import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { Grid, _ } from 'gridjs-react';

import { MasterContext } from 'contexts/master';
import { UserContext } from 'contexts/user';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const Users = () => {
  const navigate = useNavigate();

  const masterCtx = useContext(MasterContext);
  const userCtx = useContext(UserContext);

  return (
    <>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">User</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Users
          </li>
        </ol>
        <h2 className="main-title">Users</h2>

        {masterCtx.isInitializing || userCtx.isInitializing ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Button
              variant="primary"
              className="mb-2 text-align-right"
              onClick={() => navigate('/user/users/add')}>
              Add User
            </Button>
            <Grid
              columns={['No', 'Username', 'Name', 'Privilege', 'Actions']}
              data={userCtx.all.users.map((user, idx) => [
                idx + 1,
                user.username,
                user.name,
                masterCtx.all.privileges.find(
                  (privilege) => privilege.privilege_id === user.privilege_id
                )?.privilege_name || 'N/A',
                _(
                  <Button
                    variant="warning"
                    disabled={userCtx.isLoading}
                    onClick={() => navigate(`/user/users/edit/${user.username}`)}>
                    <i className="ri-edit-line" />
                  </Button>
                ),
              ])}
              search
              pagination={{ limit: 10 }}
              sort
              resizable
              className={{ table: 'table table-bordered mb-0' }}
            />
          </>
        )}

        <Footer />
      </div>
    </>
  );
};

export default Users;
