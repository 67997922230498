import React from 'react';

// Dashboard
import MainDashboard from 'views/dashboard/Main';

// Master
import Privileges from 'views/master/Privileges';
import AddPrivilege from 'views/master/Privileges/add';
import EditPrivilege from 'views/master/Privileges/edit';
import Categories from 'views/master/Categories';
import AddCategory from 'views/master/Categories/add';
import EditCategory from 'views/master/Categories/edit';
import Subcategories from 'views/master/Subcategories';
import AddSubcategory from 'views/master/Subcategories/add';
import EditSubcategory from 'views/master/Subcategories/edit';
import CafeMenus from 'views/master/CafeMenus';
import AddCafeMenu from 'views/master/CafeMenus/add';
import EditCafeMenu from 'views/master/CafeMenus/edit';
import CafeBranches from 'views/master/CafeBranches';
import AddCafeBranch from 'views/master/CafeBranches/add';
import EditCafeBranch from 'views/master/CafeBranches/edit';
import Materials from 'views/master/Materials';
import AddMaterial from 'views/master/Materials/add';
import EditMaterial from 'views/master/Materials/edit';
import Measurements from 'views/master/Measurements';
import AddMeasurement from 'views/master/Measurements/add';
import EditMeasurement from 'views/master/Measurements/edit';

// Sales
import Cashier from 'views/sales/Cashier';
import SalesReporting from 'views/sales/SalesReporting';

// Inventory
import Inventories from 'views/inventory/Inventories';
import InventoryDetail from 'views/inventory/Inventories/detail';
import AddInventory from 'views/inventory/Inventories/detail/add';
import EditInventory from 'views/inventory/Inventories/detail/edit';
import InventoryReporting from 'views/inventory/InventoryReporting';

// User
import Users from 'views/user/Users';
import AddUser from 'views/user/Users/add';
import EditUser from 'views/user/Users/edit';
import EditProfile from 'views/user/Profile/edit';

const protectedRoutes = [
  { path: '/dashboard/main', element: <MainDashboard /> },
  { path: '/master/privileges', element: <Privileges /> },
  { path: '/master/privileges/add', element: <AddPrivilege /> },
  { path: '/master/privileges/edit/:id', element: <EditPrivilege /> },
  { path: '/master/categories', element: <Categories /> },
  { path: '/master/categories/add', element: <AddCategory /> },
  { path: '/master/categories/edit/:id', element: <EditCategory /> },
  { path: '/master/subcategories', element: <Subcategories /> },
  { path: '/master/subcategories/add', element: <AddSubcategory /> },
  { path: '/master/subcategories/edit/:id', element: <EditSubcategory /> },
  { path: '/master/cafe-menus', element: <CafeMenus /> },
  { path: '/master/cafe-menus/add', element: <AddCafeMenu /> },
  { path: '/master/cafe-menus/edit/:id', element: <EditCafeMenu /> },
  { path: '/master/cafe-branches', element: <CafeBranches /> },
  { path: '/master/cafe-branches/add', element: <AddCafeBranch /> },
  { path: '/master/cafe-branches/edit/:id', element: <EditCafeBranch /> },
  { path: '/master/materials', element: <Materials /> },
  { path: '/master/materials/add', element: <AddMaterial /> },
  { path: '/master/materials/edit/:id', element: <EditMaterial /> },
  { path: '/master/measurements', element: <Measurements /> },
  { path: '/master/measurements/add', element: <AddMeasurement /> },
  { path: '/master/measurements/edit/:id', element: <EditMeasurement /> },
  { path: '/sales/cashier', element: <Cashier /> },
  { path: '/sales/reporting', element: <SalesReporting /> },
  { path: '/inventory/inventories', element: <Inventories /> },
  { path: '/inventory/inventories/:id', element: <InventoryDetail /> },
  { path: '/inventory/inventories/:id/add', element: <AddInventory /> },
  { path: '/inventory/inventories/:id/edit', element: <EditInventory /> },
  { path: '/inventory/reporting', element: <InventoryReporting /> },
  { path: '/user/users', element: <Users /> },
  { path: '/user/users/add', element: <AddUser /> },
  { path: '/user/users/edit/:id', element: <EditUser /> },
  { path: '/user/profile/edit/:id', element: <EditProfile /> },
];

export default protectedRoutes;
