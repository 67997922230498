import { useEffect, useState } from 'react';

import { AuthContext } from './AuthContext';
import { BASE_API_URL } from 'constants/api';
import { postFetcher, putFetcher } from 'utils/fetcher';
import { getToken, setToken, removeToken } from 'utils/storeToken';
import LoadingScreen from 'components/LoadingScreen';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingScreen, setIsLoadingScreen] = useState(true);

  const token = getToken();

  const signIn = async (payload) => {
    try {
      setIsLoading(true);

      const response = await postFetcher(`${BASE_API_URL}/auth/login`, payload);

      setToken(response.data.access_token);
      setIsAuthenticated(true);
    } catch (error) {
      throw new Error(`Failed to sign in: ${error.response.data.error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = () => {
    removeToken();
    setUser(null);
    setIsAuthenticated(false);
  };

  const requestResetPassword = async (payload) => {
    try {
      setIsLoading(true);

      const response = await postFetcher(`${BASE_API_URL}/users/request_reset_password`, payload);

      return response.data;
    } catch (error) {
      throw new Error('Failed to request reset password!');
    } finally {
      setIsLoading(false);
    }
  };

  const resetPassword = async (id, payload) => {
    try {
      setIsLoading(true);

      const response = await postFetcher(`${BASE_API_URL}/users/reset_password?id=${id}`, payload);

      return response.data;
    } catch (error) {
      throw new Error('Failed to reset password!');
    } finally {
      setIsLoading(false);
    }
  };

  const changePassword = async (id, payload) => {
    try {
      setIsLoading(true);

      const response = await putFetcher(
        `${BASE_API_URL}/users/change_password?id=${id}`,
        payload,
        token
      );

      return response.data;
    } catch (error) {
      throw new Error('Failed to change password!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      const getUser = async () => {
        try {
          const response = await postFetcher(`${BASE_API_URL}/auth/me?token=${token}`, {}, token);

          setUser(response.data);
          setIsAuthenticated(true);
        } catch (error) {
          removeToken();
          setUser(null);
          setIsAuthenticated(false);
          return new Error('Failed to get user!');
        } finally {
          setIsLoadingScreen(false);
        }
      };

      getUser();
    } else {
      setUser(null);
      setIsAuthenticated(false);
      setIsLoadingScreen(false);
    }
  }, [token, isAuthenticated]);

  if (isLoadingScreen) return <LoadingScreen />;

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        isLoadingScreen,
        signIn,
        signOut,
        requestResetPassword,
        resetPassword,
        changePassword,
        setUser,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
