import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Grid, _ } from 'gridjs-react';

import { MasterContext } from 'contexts/master';
import ActionModal from 'components/ActionModal';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const Privileges = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  const masterCtx = useContext(MasterContext);
  const navigate = useNavigate();

  const handleDeleteClick = async () => {
    try {
      await masterCtx.destroy('privileges', 'privilege', deletingId);

      toast.success('Successfully deleted privilege!');
    } catch (error) {
      toast.error('Failed to delete privilege!');
    }

    setIsDeleting(false);
    setDeletingId(null);
  };

  return (
    <>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Master</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Privileges
          </li>
        </ol>
        <h2 className="main-title">Privileges</h2>

        {masterCtx.isInitializing ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Button
              variant="primary"
              className="mb-2 text-align-right"
              onClick={() => navigate('/master/privileges/add')}>
              Add Privilege
            </Button>

            <Grid
              columns={['No', 'Name', 'Actions']}
              data={masterCtx.all.privileges.map((privilege, idx) => [
                idx + 1,
                privilege.privilege_name,
                _(
                  <div className="d-flex gap-2">
                    <Button
                      variant="warning"
                      disabled={masterCtx.isLoading}
                      onClick={() => navigate(`/master/privileges/edit/${privilege.privilege_id}`)}>
                      <i className="ri-edit-line" />
                    </Button>
                    <Button
                      variant="danger"
                      disabled={masterCtx.isLoading}
                      onClick={() => {
                        setIsDeleting(true);
                        setDeletingId(privilege.privilege_id);
                      }}>
                      <i className="ri-delete-bin-line" />
                    </Button>
                  </div>
                ),
              ])}
              search
              pagination={{ limit: 10 }}
              sort
              resizable
              className={{ table: 'table table-bordered mb-0' }}
            />
          </>
        )}

        <ActionModal
          isOpen={isDeleting}
          onDismiss={() => setIsDeleting(false)}
          onConfirm={handleDeleteClick}
          isLoading={masterCtx.isLoading}
        />

        <Footer />
      </div>
    </>
  );
};

export default Privileges;
