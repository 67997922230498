import { useContext, useState } from 'react';
import { Button, Card, Container, Form, Spinner } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthContext } from 'contexts/auth';
import pageSvg from 'assets/svg/forgot_password.svg';

const ResetPassword = () => {
  const [form, setForm] = useState({ password: '', password_confirmation: '' });

  const [searchParams] = useSearchParams();

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (form.password !== form.password_confirmation) {
      return toast.error('Password confirmation does not match!');
    }

    try {
      await authCtx.resetPassword(searchParams.get('id'), form);

      authCtx.signOut();
      toast.success('Successfully reset password!');
      navigate('/auth/login');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div
      className="page-auth"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div className="content">
        <Container>
          <Card className="card-auth">
            <Card.Body className="text-center">
              <div className="mb-5">
                <object
                  type="image/svg+xml"
                  data={pageSvg}
                  className="w-50"
                  aria-label="svg image"></object>
              </div>
              <Card.Title>Reset your password</Card.Title>
              <Card.Text className="mb-5">Reset your password for the next login.</Card.Text>

              <Form onSubmit={handleFormSubmit}>
                <Form.Group className="mb-4" controlId="password">
                  <Form.Label className="d-flex justify-content-between">Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={form.password}
                    placeholder="Enter your new password"
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group className="mb-4" controlId="password_confirmation">
                  <Form.Label className="d-flex justify-content-between">
                    Password Confirmation
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password_confirmation"
                    value={form.password_confirmation}
                    placeholder="Re-enter your new password"
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Button
                  type="submit"
                  variant="primary"
                  className="btn-sign"
                  disabled={authCtx.isLoading}>
                  {authCtx.isLoading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Resetting
                    </>
                  ) : (
                    'Reset'
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
};

export default ResetPassword;
