import { useContext, useEffect, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthContext } from 'contexts/auth';
import appLogo from 'assets/img/logo.png';
import ForgotPasswordModal from 'components/ForgotPasswordModal';

const SignIn = () => {
  const [form, setForm] = useState({ username: '', password: '' });
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await authCtx.signIn(form);

      toast.success('Successfully signed in!');
      navigate('/dashboard/main');
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (authCtx.user)
      authCtx.user.privilege === 'P2300006'
        ? navigate('/sales/cashier')
        : navigate('/dashboard/main');
  }, [authCtx.user]);

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header className="text-center">
          <img src={appLogo} alt="" width="50%" />
        </Card.Header>

        <Card.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group className="mb-4" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={form.username}
                placeholder="Enter your username"
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-4" controlId="password">
              <Form.Label className="d-flex justify-content-between">
                Password <Link onClick={() => setIsForgotPassword(true)}>Forgot password?</Link>
              </Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={form.password}
                placeholder="Enter your password"
                onChange={handleInputChange}
              />
            </Form.Group>

            <Button
              type="submit"
              variant="primary"
              className="btn-sign"
              disabled={authCtx.isLoading}>
              {authCtx.isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Signing In
                </>
              ) : (
                'Sign In'
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>

      <ForgotPasswordModal isOpen={isForgotPassword} onDismiss={() => setIsForgotPassword(false)} />
    </div>
  );
};

export default SignIn;
