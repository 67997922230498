import { Button, Modal, Spinner } from 'react-bootstrap';

const ActionModal = ({ isOpen, onDismiss, onConfirm, isLoading }) => {
  return (
    <Modal show={isOpen} onHide={onDismiss} centered>
      <Modal.Header>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Are you sure you want to delete this item?</h4>
        <p>
          You will not be able to recover this item after deleting it. Please make sure you really
          want to delete this item.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onDismiss} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm} disabled={isLoading}>
          {isLoading ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Deleting
            </>
          ) : (
            'Delete'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ActionModal;
