import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Grid } from 'gridjs-react';
import Select from 'react-select';
import moment from 'moment';
import * as XLSX from 'xlsx';

import { InventoryContext } from 'contexts/inventory';
import { MasterContext } from 'contexts/master';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const InventoryReporting = () => {
  const [form, setForm] = useState({
    startDate: '',
    endDate: '',
    branch: '',
  });

  const inventoryCtx = useContext(InventoryContext);
  const masterCtx = useContext(MasterContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleSelectChange = (e, name) => {
    setForm({ ...form, [name]: e ? e.value : '' });
  };

  const exportToXLSX = () => {
    const reportJson = inventoryCtx.all.inventories
      .filter(
        (inventory) =>
          (form.startDate
            ? moment(inventory.date_received).format('YYYY-MM-DDTHH:mm:ss') >= form.startDate
            : true) &&
          (form.endDate
            ? moment(inventory.date_received).format('YYYY-MM-DDTHH:mm:ss') <= form.endDate
            : true) &&
          (form.branch ? inventory.branch_name === form.branch : true)
      )
      .map((inventory) => ({
        'Material Name': inventory.material_name,
        'Date Received': moment(inventory.date_received).format('MM/DD/YYYY HH:mm'),
        Quantity:
          inventory.quantity +
          ' ' +
          masterCtx.all.measurements.find(
            (measurement) => measurement.measurement_id === inventory.measurement_to
          )?.measurement_name,
      }));

    const ws = XLSX.utils.json_to_sheet(reportJson);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Inventory Report - Maddie Coffee.xlsx');
  };

  return (
    <>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Inventory</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Reporting
          </li>
        </ol>
        <h2 className="main-title">Reporting</h2>

        {masterCtx.isInitializing || inventoryCtx.isInitializing ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Card className="mb-3">
              <Card.Header>Filter</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="startDate"
                        value={form.startDate}
                        placeholder="Enter start date"
                        required
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="endDate"
                        value={form.endDate}
                        placeholder="Enter end date"
                        required
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Branch</Form.Label>
                      <Select
                        name="branch"
                        options={masterCtx.all.branches.map((branch) => ({
                          value: branch.branch_name,
                          label: branch.branch_name,
                        }))}
                        value={
                          form.branch && {
                            value: form.branch,
                            label: form.branch,
                          }
                        }
                        placeholder="Select branch"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#8c7851',
                            primary25: '#eaddcf',
                          },
                        })}
                        isSearchable
                        isClearable
                        required
                        onChange={(e) => handleSelectChange(e, 'branch')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Button variant="primary" className="mb-2" onClick={exportToXLSX}>
              Export to XLSX
            </Button>

            <Grid
              columns={['Material Name', 'Date Received', 'Quantity']}
              data={inventoryCtx.all.inventories
                .filter(
                  (inventory) =>
                    (form.startDate
                      ? moment(inventory.date_received).format('YYYY-MM-DDTHH:mm:ss') >=
                        form.startDate
                      : true) &&
                    (form.endDate
                      ? moment(inventory.date_received).format('YYYY-MM-DDTHH:mm:ss') <=
                        form.endDate
                      : true) &&
                    (form.branch ? inventory.branch_name === form.branch : true)
                )
                .map((inventory) => [
                  inventory.material_name,
                  moment(inventory.date_received).format('MM/DD/YYYY HH:mm'),
                  inventory.quantity +
                    ' ' +
                    masterCtx.all.measurements.find(
                      (measurement) => measurement.measurement_id === inventory.measurement_to
                    )?.measurement_name,
                ])}
              pagination={{ limit: 10 }}
              resizable
              className={{ table: 'table table-bordered mb-0' }}
            />
          </>
        )}

        <Footer />
      </div>
    </>
  );
};

export default InventoryReporting;
