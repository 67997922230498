import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthContext } from 'contexts/auth';
import Sidebar from './Sidebar';

export default function Main() {
  const bc = document.body.classList;

  // set sidebar to offset
  bc.remove('sidebar-offset');

  // auto close sidebar when switching pages in mobile
  bc.remove('sidebar-show');

  // scroll to top when switching pages
  window.scrollTo(0, 0);

  const authCtx = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (authCtx.user) {
      if (
        ['privileges', 'cafe-branches', 'users'].includes(location.pathname.split('/')[2]) &&
        authCtx.user.privilege_id === 'P2300005'
      ) {
        toast.error('You are not authorized to access this page!');
        navigate('/dashboard/main');
      } else if (
        [
          'main',
          'privileges',
          'categories',
          'subcategories',
          'cafe-menus',
          'cafe-branches',
          'materials',
          'measurements',
          'users',
        ].includes(location.pathname.split('/')[2]) &&
        authCtx.user.privilege_id === 'P2300006'
      ) {
        if (location.pathname.split('/')[2] !== 'main') {
          toast.error('You are not authorized to access this page!');
        }
        navigate('/sales/cashier');
      } else {
        navigate(location.pathname === '/' ? '/dashboard/main' : location.pathname);
      }
    } else {
      navigate('/auth/login');
    }
  }, [authCtx.user]);

  return (
    <React.Fragment>
      <Sidebar />
      <Outlet />
    </React.Fragment>
  );
}
