const dashboardMenu = [
  {
    label: 'Main',
    link: '/dashboard/main',
    icon: 'ri-dashboard-line',
  },
];

const masterMenu = [
  {
    label: 'Privileges',
    link: '/master/privileges',
    icon: 'ri-vip-crown-line',
  },
  {
    label: 'Categories',
    link: '/master/categories',
    icon: 'ri-list-check',
  },
  {
    label: 'Subcategories',
    link: '/master/subcategories',
    icon: 'ri-list-check-2',
  },
  {
    label: 'Cafe Menus',
    link: '/master/cafe-menus',
    icon: 'ri-file-list-2-line',
  },
  {
    label: 'Cafe Branches',
    link: '/master/cafe-branches',
    icon: 'ri-git-branch-line',
  },
  {
    label: 'Materials',
    link: '/master/materials',
    icon: 'ri-dropbox-line',
  },
  {
    label: 'Measurements',
    link: '/master/measurements',
    icon: 'ri-pencil-ruler-2-line',
  },
];

const salesMenu = [
  {
    label: 'Kasir',
    link: '/sales/cashier',
    icon: 'ri-shopping-cart-line',
  },
  {
    label: 'Reporting',
    link: '/sales/reporting',
    icon: 'ri-file-chart-line',
  },
];

const inventoryMenu = [
  {
    label: 'Inventories',
    link: '/inventory/inventories',
    icon: 'ri-safe-2-line',
  },
  {
    label: 'Reporting',
    link: '/inventory/reporting',
    icon: 'ri-file-chart-line',
  },
];

const userMenu = [
  {
    label: 'Users',
    link: '/user/users',
    icon: 'ri-user-line',
  },
];

export { dashboardMenu, masterMenu, salesMenu, inventoryMenu, userMenu };
