import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';

import { AuthContext } from 'contexts/auth';
import { InventoryContext } from 'contexts/inventory';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const EditInventory = () => {
  const { id } = useParams();

  const [form, setForm] = useState({ material_id: '', quantity: '', date_received: '' });
  const [detail, setDetail] = useState(null);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);

  const authCtx = useContext(AuthContext);
  const inventoryCtx = useContext(InventoryContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await inventoryCtx.update(id, {
        ...form,
        uploaded_by: authCtx.user.username,
        branch_id: authCtx.user.branch_id,
      });

      toast.success('Successfully updated inventory!');
      navigate(`/inventory/inventories/${detail.material_id}`);
    } catch (error) {
      toast.error('Failed to update inventory!');
    }
  };

  useEffect(() => {
    const getDetail = async () => {
      try {
        setIsLoadingDetail(true);

        const data = await inventoryCtx.detailEdit(id);

        setDetail(data);
      } catch (error) {
        toast.error('Failed to fetch inventory detail!');
      } finally {
        setIsLoadingDetail(false);
      }
    };

    getDetail();
  }, []);

  useEffect(() => {
    setForm({
      ...form,
      material_id: detail?.material_id,
      quantity: detail?.quantity,
      date_received: moment(detail?.date_received).format('YYYY-MM-DDTHH:mm:ss'),
    });
  }, [detail]);

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Inventory</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/inventory/inventories">Inventories</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/inventory/inventories/${id}`}>Details</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit
          </li>
        </ol>
        <h2 className="main-title">Edit Material In & Out</h2>

        {isLoadingDetail || !detail ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Form onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3" controlId="material_id">
              <Form.Label>Material Name</Form.Label>
              <Form.Control
                type="text"
                name="material_id"
                value={detail.material_name}
                placeholder="Enter material name"
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="quantity">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                name="quantity"
                value={form.quantity}
                placeholder="Enter quantity"
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="date_received">
              <Form.Label>Date Received</Form.Label>
              <Form.Control
                type="datetime-local"
                name="date_received"
                value={form.date_received}
                placeholder="Enter date received"
                required
                onChange={handleInputChange}
              />
            </Form.Group>

            <div className="d-flex gap-2">
              <Button variant="primary" type="submit" disabled={inventoryCtx.isLoading}>
                {inventoryCtx.isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" />
                    Saving
                  </>
                ) : (
                  'Save'
                )}
              </Button>
              <Button
                variant="secondary"
                disabled={inventoryCtx.isLoading}
                onClick={() => navigate(`/inventory/inventories/${detail.material_id}`)}>
                Cancel
              </Button>
            </div>
          </Form>
        )}

        <Footer />
      </div>
    </>
  );
};

export default EditInventory;
