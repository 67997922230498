import appLogo from 'assets/img/logo.png';

const LoadingScreen = () => {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img src={appLogo} alt="" width="50%" style={{ maxWidth: 200 }} />
    </div>
  );
};

export default LoadingScreen;
