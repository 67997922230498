import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Main from 'layouts/Main';
import NotFound from 'views/NotFound';
import publicRoutes from 'routes/PublicRoutes';
import protectedRoutes from 'routes/ProtectedRoutes';

// import css
import 'react-toastify/dist/ReactToastify.css';
import 'assets/css/remixicon.css';

// import scss
import 'scss/style.scss';

// set skin on load
window.addEventListener('load', function () {
  let skinMode = localStorage.getItem('skin-mode');
  let HTMLTag = document.querySelector('html');

  if (skinMode) {
    HTMLTag.setAttribute('data-skin', skinMode);
  }
});

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            {protectedRoutes.map((route, index) => {
              return <Route path={route.path} element={route.element} key={index} />;
            })}
          </Route>
          {publicRoutes.map((route, index) => {
            return <Route path={route.path} element={route.element} key={index} />;
          })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="top-right" />
    </>
  );
};

export default App;
