import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';

import { AuthContext } from 'contexts/auth';
import userAvatar from 'assets/img/img1.jpg';

export default function Header() {
  const authCtx = useContext(AuthContext);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link">
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains('sidebar-offset');
    if (isOffset) {
      document.body.classList.toggle('sidebar-show');
    } else {
      if (window.matchMedia('(max-width: 991px)').matches) {
        document.body.classList.toggle('sidebar-show');
      } else {
        document.body.classList.toggle('sidebar-hide');
      }
    }
  };

  const handleSignOut = () => {
    authCtx.signOut();
    toast.success('Succesfully signed out!');
  };

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3  me-auto">
        <i className="ri-menu-2-fill" />
      </Link>

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={userAvatar} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3">
              <img src={userAvatar} alt="" />
            </div>
            <h5 className="mb-1 text-dark fw-semibold">{authCtx.user?.name}</h5>

            <nav className="nav">
              <Link to={`/user/profile/edit/${authCtx.user?.username}`}>
                <i className="ri-edit-2-line" /> Edit Profile
              </Link>
              <Link to="#">
                <i className="ri-profile-line" /> View Profile
              </Link>
            </nav>
            <hr />
            <nav className="nav">
              <Link onClick={handleSignOut}>
                <i className="ri-logout-box-r-line" /> Log Out
              </Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
