import axios from 'axios';

export const getFetcher = (url, token, privilegeId) => {
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}`, privilege: privilegeId },
  });
};

export const postFetcher = (url, payload, token, privilegeId) => {
  return axios.post(url, payload, {
    headers: { Authorization: `Bearer ${token}`, privilege: privilegeId },
  });
};

export const putFetcher = (url, payload, token, privilegeId) => {
  return axios.put(url, payload, {
    headers: { Authorization: `Bearer ${token}`, privilege: privilegeId },
  });
};

export const deleteFetcher = (url, token, privilegeId) => {
  return axios.delete(url, {
    headers: { Authorization: `Bearer ${token}`, privilege: privilegeId },
  });
};
