import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Grid, _ } from 'gridjs-react';

import { MasterContext } from 'contexts/master';
import ActionModal from 'components/ActionModal';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const CafeMenus = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  const navigate = useNavigate();
  const masterCtx = useContext(MasterContext);

  const handleDeleteClick = async () => {
    try {
      await masterCtx.destroy('menus', 'cafe_menu', deletingId);

      toast.success('Successfully deleted menu!');
    } catch (error) {
      toast.error('Failed to delete menu!');
    }

    setIsDeleting(false);
    setDeletingId(null);
  };

  return (
    <>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Master</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Cafe Menus
          </li>
        </ol>
        <h2 className="main-title">Cafe Menus</h2>

        {masterCtx.isInitializing ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Button
              variant="primary"
              className="mb-2 text-align-right"
              onClick={() => navigate('/master/cafe-menus/add')}>
              Add Menu
            </Button>

            <Grid
              columns={['No', 'Name', 'Subcategory', 'Ingredients', 'Price', 'Actions']}
              data={masterCtx.all.menus.map((menu, idx) => [
                idx + 1,
                menu.cafe_menu_name,
                masterCtx.all.subcategories.find(
                  (subcategory) => subcategory.submenu_category_id === menu.submenu_category_id
                )?.submenu_category_name || 'N/A',
                _(
                  <ul className="list-unstyled mb-0">
                    {menu.data_ingredient.map((ingredient) => (
                      <li key={ingredient.ingredient.material_id}>
                        {ingredient.ingredient.material_name} ({ingredient.quantity}{' '}
                        {
                          masterCtx.all.measurements.find(
                            (measurement) =>
                              measurement.measurement_id === ingredient.ingredient.measurement_to
                          )?.measurement_name
                        }
                        )
                      </li>
                    ))}
                  </ul>
                ),
                new Intl.NumberFormat('id-ID', {
                  style: 'currency',
                  currency: 'IDR',
                }).format(menu.cafe_menu_price),
                _(
                  <div className="d-flex gap-2">
                    <Button
                      variant="warning"
                      disabled={masterCtx.isLoading}
                      onClick={() => navigate(`/master/cafe-menus/edit/${menu.cafe_menu_id}`)}>
                      <i className="ri-edit-line" />
                    </Button>
                    <Button
                      variant="danger"
                      disabled={masterCtx.isLoading}
                      onClick={() => {
                        setIsDeleting(true);
                        setDeletingId(menu.cafe_menu_id);
                      }}>
                      <i className="ri-delete-bin-line" />
                    </Button>
                  </div>
                ),
              ])}
              search
              pagination={{ limit: 10 }}
              sort
              resizable
              className={{ table: 'table table-bordered mb-0' }}
            />
          </>
        )}

        <ActionModal
          isOpen={isDeleting}
          onDismiss={() => setIsDeleting(false)}
          onConfirm={handleDeleteClick}
          isLoading={masterCtx.isLoading}
        />

        <Footer />
      </div>
    </>
  );
};

export default CafeMenus;
