import React from 'react';
import ReactDOM from 'react-dom';

import { AuthProvider } from 'contexts/auth';
import { InventoryProvider } from 'contexts/inventory';
import { MasterProvider } from 'contexts/master';
import { OrderProvider } from 'contexts/order';
import { UserProvider } from 'contexts/user/UserProvider';
import reportWebVitals from './reportWebVitals';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <MasterProvider>
          <OrderProvider>
            <InventoryProvider>
              <App />
            </InventoryProvider>
          </OrderProvider>
        </MasterProvider>
      </UserProvider>
    </AuthProvider>
    <div className="main-backdrop" />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
