import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { MasterContext } from 'contexts/master';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const EditMaterial = () => {
  const { id } = useParams();

  const [form, setForm] = useState({ material_name: '', measurement_to: '' });

  const masterCtx = useContext(MasterContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleSelectChange = (e, name) => {
    setForm({ ...form, [name]: e ? e.value : '' });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await masterCtx.update('materials', 'material', id, form);

      toast.success('Successfully updated material!');
      navigate('/master/materials');
    } catch (error) {
      toast.error('Failed to update material!');
    }
  };

  useEffect(() => {
    const material = masterCtx.all.materials.find((material) => material.material_id === id);

    setForm({
      material_name: material.material_name,
      measurement_to: material.measurement_to,
    });
  }, []);

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Master</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/master/materials">Materials</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit
          </li>
        </ol>
        <h2 className="main-title">Edit Material</h2>

        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="material_name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="material_name"
              value={form.material_name}
              placeholder="Enter material name"
              required
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="measurement_to">
            <Form.Label>Measurement</Form.Label>
            <Select
              name="measurement_to"
              options={masterCtx.all.measurements.map((measurement) => ({
                value: measurement.measurement_id,
                label: measurement.measurement_name,
              }))}
              value={
                form.measurement_to && {
                  value: form.measurement_to,
                  label: masterCtx.all.measurements.find(
                    (measurement) => measurement.measurement_id === form.measurement_to
                  )?.measurement_name,
                }
              }
              placeholder="Select measurement unit"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#8c7851',
                  primary25: '#eaddcf',
                },
              })}
              isSearchable
              isClearable
              required
              onChange={(e) => handleSelectChange(e, 'measurement_to')}
            />
          </Form.Group>

          <div className="d-flex gap-2">
            <Button variant="primary" type="submit" disabled={masterCtx.isLoading}>
              {masterCtx.isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Button
              variant="secondary"
              disabled={masterCtx.isLoading}
              onClick={() => navigate('/master/materials')}>
              Cancel
            </Button>
          </div>
        </Form>

        <Footer />
      </div>
    </>
  );
};

export default EditMaterial;
