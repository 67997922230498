import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Grid } from 'gridjs-react';
import Select from 'react-select';
import moment from 'moment';
import * as XLSX from 'xlsx';

import { MasterContext } from 'contexts/master';
import { OrderContext } from 'contexts/order';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

const SalesReporting = () => {
  const [form, setForm] = useState({
    startDate: '',
    endDate: '',
    branch: '',
  });

  const masterCtx = useContext(MasterContext);
  const orderCtx = useContext(OrderContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleSelectChange = (e, name) => {
    setForm({ ...form, [name]: e ? e.value : '' });
  };

  const exportToXLSX = () => {
    const orderJson = orderCtx.all.orders
      .filter(
        (order) =>
          (form.startDate
            ? moment(order.created_at).format('YYYY-MM-DDTHH:mm:ss') >= form.startDate
            : true) &&
          (form.endDate
            ? moment(order.created_at).format('YYYY-MM-DDTHH:mm:ss') <= form.endDate
            : true) &&
          (form.branch ? order.branch_name === form.branch : true)
      )
      .map((order) => ({
        'Order ID': order.order_id,
        'Order Time': moment(order.created_at).format('MM/DD/YYYY HH:mm'),
        'Cashier Name': order.name,
        'Branch Name': order.branch_name,
        Subtotal: new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(order.subtotal),
        Tax: new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(order.tax),
        Discount: new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(order.discount),
        'Total Price': new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(order.total_price),
      }));

    const ws = XLSX.utils.json_to_sheet(orderJson);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Sales Report - Maddie Coffee.xlsx');
  };

  return (
    <>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">Sales</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Reporting
          </li>
        </ol>
        <h2 className="main-title">Reporting</h2>

        {masterCtx.isInitializing || orderCtx.isInitializing ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Card className="mb-3">
              <Card.Header>Filter</Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="startDate"
                        value={form.startDate}
                        placeholder="Enter start date"
                        required
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="endDate"
                        value={form.endDate}
                        placeholder="Enter end date"
                        required
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Branch</Form.Label>
                      <Select
                        name="branch"
                        options={masterCtx.all.branches.map((branch) => ({
                          value: branch.branch_name,
                          label: branch.branch_name,
                        }))}
                        value={
                          form.branch && {
                            value: form.branch,
                            label: form.branch,
                          }
                        }
                        placeholder="Select branch"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: '#8c7851',
                            primary25: '#eaddcf',
                          },
                        })}
                        isSearchable
                        isClearable
                        required
                        onChange={(e) => handleSelectChange(e, 'branch')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Button variant="primary" className="mb-2" onClick={exportToXLSX}>
              Export to XLSX
            </Button>

            <Grid
              columns={[
                'Order ID',
                'Order Time',
                'Cashier Name',
                'Branch Name',
                'Subtotal',
                'Tax',
                'Discount',
                'Total Price',
              ]}
              data={orderCtx.all.orders
                .filter(
                  (order) =>
                    (form.startDate
                      ? moment(order.created_at).format('YYYY-MM-DDTHH:mm:ss') >= form.startDate
                      : true) &&
                    (form.endDate
                      ? moment(order.created_at).format('YYYY-MM-DDTHH:mm:ss') <= form.endDate
                      : true) &&
                    (form.branch ? order.branch_name === form.branch : true)
                )
                .map((order) => [
                  order.order_id,
                  moment(order.created_at).format('MM/DD/YYYY HH:mm'),
                  order.name,
                  order.branch_name,
                  new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  }).format(order.subtotal),
                  new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  }).format(order.tax),
                  new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  }).format(order.discount),
                  new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  }).format(order.total_price),
                ])}
              pagination={{ limit: 10 }}
              resizable
              className={{ table: 'table table-bordered mb-0' }}
            />
          </>
        )}

        <Footer />
      </div>
    </>
  );
};

export default SalesReporting;
