import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { UserContext } from 'contexts/user';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';
import { AuthContext } from 'contexts/auth';

const EditProfile = () => {
  const { id } = useParams();

  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
  });

  const authCtx = useContext(AuthContext);
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await userCtx.changeProfile(id, form);

      toast.success('Successfully updated your profile!');
      navigate('/dashboard/main');
    } catch (error) {
      toast.error('Failed to update your profile!');
    }
  };

  useEffect(() => {
    const user = authCtx.user;

    setForm({ ...form, name: user.name, email: user.email });
  }, []);

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <ol className="breadcrumb fs-sm mb-2">
          <li className="breadcrumb-item">
            <Link to="#">User</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="#">Profile</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit
          </li>
        </ol>
        <h2 className="main-title">Edit Profile</h2>

        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={form.name}
              placeholder="Enter your new name"
              required
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={form.email}
              placeholder="Enter your new email"
              required
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={form.password}
              placeholder="Enter your new password"
              required
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="password_confirmation">
            <Form.Label>Password Confirmation</Form.Label>
            <Form.Control
              type="password"
              name="password_confirmation"
              value={form.password_confirmation}
              placeholder="Re-enter your new password"
              required
              onChange={handleInputChange}
            />
          </Form.Group>

          <div className="d-flex gap-2">
            <Button variant="primary" type="submit" disabled={userCtx.isLoading}>
              {userCtx.isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Button
              variant="secondary"
              disabled={userCtx.isLoading}
              onClick={() => navigate('/dashboard/main')}>
              Cancel
            </Button>
          </div>
        </Form>

        <Footer />
      </div>
    </>
  );
};

export default EditProfile;
