import { useContext, useEffect, useState } from 'react';

import { UserContext } from './UserContext';
import { BASE_API_URL } from 'constants/api';
import { AuthContext } from 'contexts/auth';
import { getFetcher, postFetcher, putFetcher } from 'utils/fetcher';
import { getToken } from 'utils/storeToken';

export const UserProvider = ({ children }) => {
  const [all, setAll] = useState({ users: [] });
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);

  const token = getToken();

  const create = async (payload) => {
    try {
      setIsLoading(true);

      const response = await postFetcher(
        `${BASE_API_URL}/users/store`,
        payload,
        token,
        authCtx.user?.privilege_id
      );

      setAll({ ...all, users: [...all.users, response.data.data] });
    } catch (error) {
      throw new Error('Failed to add user!');
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (id, payload) => {
    try {
      setIsLoading(true);

      const response = await putFetcher(
        `${BASE_API_URL}/users/update/${id}`,
        payload,
        token,
        authCtx.user?.privilege_id
      );

      setAll({
        ...all,
        users: all.users.map((item) => {
          if (item.username === id) {
            return response.data.data;
          }

          return item;
        }),
      });
    } catch (error) {
      throw new Error('Failed to update user!');
    } finally {
      setIsLoading(false);
    }
  };

  const changeProfile = async (id, payload) => {
    try {
      setIsLoading(true);

      const response = await putFetcher(
        `${BASE_API_URL}/users/change_profile/${id}`,
        payload,
        token,
        authCtx.user?.privilege_id
      );

      if (authCtx.user?.privilege_id === 'P2300001') {
        setAll({
          ...all,
          users: all.users.map((item) => {
            if (item.username === id) {
              return response.data.data;
            }

            return item;
          }),
        });
      } else {
        authCtx.setUser(response.data.data);
      }
    } catch (error) {
      throw new Error('Failed to update profile!');
    } finally {
      setIsLoading(false);
    }
  };

  const detail = async (id) => {
    try {
      setIsLoading(true);

      const response = await getFetcher(
        `${BASE_API_URL}/users?id=${id}`,
        token,
        authCtx.user?.privilege_id
      );

      return response.data;
    } catch (error) {
      throw new Error('Failed to get user detail!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getAll = async () => {
      if (token && authCtx.user) {
        try {
          const usersResponse =
            authCtx.user?.privilege_id === 'P2300001'
              ? await getFetcher(`${BASE_API_URL}/users`, token, authCtx.user?.privilege_id)
              : [];

          setAll({ users: usersResponse.data });
        } catch (error) {
          return new Error('Failed to get users!');
        } finally {
          setIsInitializing(false);
        }
      }
    };

    getAll();
  }, [token, authCtx.user]);

  return (
    <UserContext.Provider
      value={{ all, isInitializing, isLoading, create, update, changeProfile, detail }}>
      {children}
    </UserContext.Provider>
  );
};
