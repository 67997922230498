import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Grid } from 'gridjs-react';
import { Chart, registerables } from 'chart.js';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

import { InventoryContext } from 'contexts/inventory';
import { OrderContext } from 'contexts/order';
import Header from 'layouts/Header';
import Footer from 'layouts/Footer';

Chart.register(...registerables);

const MainDashboard = () => {
  const [data, setData] = useState({
    todayOrder: 0,
    todayRevenue: 0,
    revenueYoy: [],
    recentOrder: [],
    recentInbound: [],
    warningStock: {},
  });

  const seriesOne = [
    {
      name: 'Revenue',
      data: data.revenueYoy.map((revenue) => revenue.total),
    },
  ];

  const optionOne = {
    colors: ['#716040'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: '#020826',
          fontSize: '11px',
        },
      },
      categories: data.revenueYoy.map((revenue) => revenue.date),
    },
    yaxis: {
      labels: {
        style: {
          colors: '#020826',
          fontSize: '11px',
        },
        formatter: (value) => {
          return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
          }).format(value);
        },
      },
    },
  };

  const inventoryCtx = useContext(InventoryContext);
  const orderCtx = useContext(OrderContext);

  useEffect(() => {
    if (orderCtx.isInitializing || inventoryCtx.isInitializing) return;

    let isMounted = true;

    const getAllData = async () => {
      try {
        const todayOrder = await orderCtx.dashboard('today_order');
        const todayRevenue = await orderCtx.dashboard('today_revenue');
        const revenueYoy = await orderCtx.dashboard('revenue_yoy');
        const recentOrder = await orderCtx.dashboard('recent');
        const recentInbound = await inventoryCtx.dashboard('recent');
        const warningStock = await inventoryCtx.dashboard('warning_stock');

        if (!isMounted) return;

        setData({
          todayOrder: todayOrder.data,
          todayRevenue: todayRevenue.data,
          revenueYoy: revenueYoy.data,
          recentInbound: recentInbound.slice(0, 5),
          recentOrder: recentOrder.slice(0, 5),
          warningStock: warningStock.data,
        });
      } catch (error) {
        toast.error('Failed to fetch dashboard data!');
      }
    };

    getAllData();

    return () => {
      isMounted = false;
    };
  }, [orderCtx.isInitializing, inventoryCtx.isInitializing]);

  return (
    <>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Main
              </li>
            </ol>
            <h4 className="main-title mb-0">Main Dashboard</h4>
          </div>
        </div>

        {orderCtx.isInitializing ||
        inventoryCtx.isInitializing ||
        orderCtx.isLoading ||
        inventoryCtx.isLoading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Row className="g-3">
            {[
              {
                label: "Today's Orders",
                icon: 'ri-shopping-bag-3-line',
                value: data.todayOrder,
              },
              {
                label: "Today's Revenue",
                icon: 'ri-briefcase-4-line',
                value: new Intl.NumberFormat('id-ID', {
                  style: 'currency',
                  currency: 'IDR',
                }).format(data.todayRevenue),
              },
              {
                label: 'Warning Stock',
                icon: 'ri-inbox-line',
                value: `${data.warningStock?.material_name} (${data.warningStock?.total} ${data.warningStock?.measurement_name})`,
              },
            ].map((card, index) => (
              <Col xs="12" xl="4" key={index}>
                <Card>
                  <Card.Body>
                    <Card.Title as="label" className="fs-sm fw-medium mb-1">
                      {card.label}
                    </Card.Title>
                    <h3 className="card-value mb-1">
                      <i className={card.icon} /> {card.value}
                    </h3>
                  </Card.Body>
                </Card>
              </Col>
            ))}

            <Col xl="12">
              <Card>
                <Card.Header>
                  <Card.Title as="h6" className="my-2">
                    Year-over-Year Revenue
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <ReactApexChart
                    series={seriesOne}
                    options={optionOne}
                    type="line"
                    height={300}
                    className="apex-chart-one mb-4"
                  />
                </Card.Body>
              </Card>
            </Col>

            <Col xl="6">
              <Card>
                <Card.Header>
                  <Card.Title as="h6" className="my-2">
                    Recent Orders
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid
                    columns={['No', 'Total', 'Date']}
                    data={data.recentOrder.map((order, idx) => [
                      idx + 1,
                      new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                      }).format(order.total_price),
                      moment(order.created_at).format('MM/DD/YYYY HH:mm'),
                    ])}
                    className={{ table: 'table table-bordered mb-0' }}
                  />
                </Card.Body>
              </Card>
            </Col>

            <Col xl="6">
              <Card>
                <Card.Header>
                  <Card.Title as="h6" className="my-2">
                    Recent Inbound Stock
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid
                    columns={['No', 'Name', 'Quantity', 'Date']}
                    data={data.recentInbound.map((stock, idx) => [
                      idx + 1,
                      stock.material_name,
                      stock.quantity,
                      moment(stock.date_received).format('MM/DD/YYYY HH:mm'),
                    ])}
                    className={{ table: 'table table-bordered mb-0' }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <Footer />
      </div>
    </>
  );
};

export default MainDashboard;
