import { useContext, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { AuthContext } from 'contexts/auth';

const ForgotPasswordModal = ({ isOpen, onDismiss }) => {
  const [form, setForm] = useState({ username: '' });

  const authCtx = useContext(AuthContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await authCtx.requestResetPassword(form);

      toast.success('Successfully sent password reset request, please check your email!');
      onDismiss();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Modal show={isOpen} onHide={onDismiss} centered>
      <Modal.Header>
        <Modal.Title>Password Reset Request</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleFormSubmit}>
        <Modal.Body>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              name="username"
              value={form.username}
              placeholder="Enter your username"
              onChange={handleInputChange}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onDismiss}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" disabled={authCtx.isLoading}>
            {authCtx.isLoading ? (
              <>
                <Spinner animation="border" size="sm" className="me-2" />
                Sending
              </>
            ) : (
              'Send'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ForgotPasswordModal;
